import React, { useEffect, useState, useContext }  from "react";
import { View, Text, FlatList, TouchableOpacity, Platform } from 'react-native';
import { TextButton } from '../../General/Other'
import { getMachines } from '../../../api/dcm_api';
import { TEXT_B, MEDIUM_B, container, LIGHT_GRAY, DEFAULT_FONT_BOLD, DARK_GRAY, 
    BUTTON_PRIMARY, BUTTON_SECONDARY, WHITE, LIGHT_GREEN, getFont } from '../../../style/index.js'
import { handleLoading } from '../../Need/'
import { UserContext } from '../../../utils/context'
import ProgressiveImage from '../../General/ProgressiveImage'


export function MachinesRow(props){

    const { languages, code } = useContext(UserContext);
    const bgColor = props.item.id === props.selectedId ? LIGHT_GREEN : Platform.OS === 'web' ? LIGHT_GRAY : WHITE
    

    return (
        <TouchableOpacity style={{padding: 16, backgroundColor: bgColor, borderRadius: 4, marginVertical: 8}} onPress={() => props.onPushRow(props.item)} >
            <View style={{flexDirection:'row', paddingBottom: 10}}>
                <View>
                    <ProgressiveImage 
                        width={120} 
                        height={120}
                        img_url={props.item.img_url} 
                        style_image={[
                            props.item.id === props.selectedId ? container.underline_box : container.not_underline_box,
                            { height: 120, width: 120, backgroundColor: WHITE, resizeMode: 'contain' }
                        ]}/>
                </View>
                
                <View style={{flex: 1, alignItems: 'flex-end'}}>
                    <Text style={{...getFont(22, DARK_GRAY, DEFAULT_FONT_BOLD), marginBottom: 10, textAlign:'right'}}>{props.item.name}</Text>
                    <TextButton 
                        onPress={() => props.onPushRow(props.item)} 
                        type={props.item.id === props.selectedId ? BUTTON_PRIMARY: BUTTON_SECONDARY}
                        title={languages["select"][code]} />
                    
                </View>
            </View>
            <View>
                <Text style={TEXT_B}>
                    {props.item.description}
                </Text>
            </View>
        </TouchableOpacity>
    )
}

export default function Machines(props) {

    
    const [machine, setMachine] = useState(props.machine);
    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: []});
    const { code } = useContext(UserContext);
    

    useEffect(() => {
        

        setStateApi({isLoading: true, error: null, response: []})
        getMachines(code).then( success => {
            if (success.response) {
                setStateApi({isLoading: false, error: null, response: success.response})
            } else {
                setStateApi({isLoading: false, error: success.error, response: []})
            }
        })

        
    }, [])

    


    return (
        <View>
            { handleLoading(props.langSigla, stateApi.isLoading, stateApi.error, 
                <FlatList data={stateApi.response} 
                    renderItem={ item => 
                    <MachinesRow {...item} 
                        language={props.language} 
                        langSigla={code}
                        selectedId={ machine ? machine.id : -1}
                        onPushRow={(m) => {
                            setMachine(m)
                            props.onSelectMachine(m)
                            props.isValid(true)
                        }}/>}/>) }
        </View>
    )
}